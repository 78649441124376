import './styles/home-list/app.scss';

// start the Stimulus application
import './bootstrap';
window.bootstrap = require("bootstrap");
const popoverTriggerList = [].slice.call(
    document.querySelectorAll('[data-bs-toggle="popover"]')
);
popoverTriggerList.map(function (el) {
    return new bootstrap.Popover(el);
});
// this "modifies" the jquery module: adding behavior to it
// the bootstrap module doesn't export/return anything
require('bootstrap');
